import React from "react";
import { SEO, MarkdownContent, DynamicScript } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class FinancingPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "cf17ef30-be34-4193-ae18-2ee7818db9d9",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__b40504e8-0e97-4711-aceb-fa068a58aa15"}
                >
                  <div>
                    <DynamicScript
                      type={"application/javascript"}
                      delay={200}
                      body={
                        "(function (w, d, s, o, f, js, fjs) {w[o] =w[o] ||function () {(w[o].q = w[o].q || []).push(arguments);};(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);})(window, document, 'script', '_hw', ' https://files.withcherry.com/widgets/widget.js');_hw('init',{debug: false,variables: {slug: 'revealaestudio',name: 'Reveal Aesthetic Studio',},styles: {primaryColor: '#295d66',secondaryColor: '#bba87e',fontFamily: 'Lato',},},['all', 'hero', 'howitworks', 'testimony', 'faq', 'calculator']);"
                      }
                    />
                    <div id={"all"} />
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 21358 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
